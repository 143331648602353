import React from 'react';

import { ITEM_CLASS_NAME, ITEM_SELECTED_CLASS_NAME, ITEM_TEXT_CLASS_NAME, MAIN_CLASS_NAME } from './const';

import './index.less';

interface ISuggestedActionsProps {
  items: string[];
  selectedItem?: string | null;
}

const SuggestedActions: React.FC<ISuggestedActionsProps> = ({ items, selectedItem }) => (
  <div className={MAIN_CLASS_NAME}>
    {items.map((m) => (
      <div key={m} className={`${ITEM_CLASS_NAME} ${selectedItem == m ? ITEM_SELECTED_CLASS_NAME : ''}`}>
        <div className={ITEM_TEXT_CLASS_NAME}>{m}</div>
      </div>
    ))}
  </div>
);

export default SuggestedActions;
