import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbTypography from '../../../../components/common/IbTypography';
import IbCheckbox from '../../../../components/common/IbCheckbox';
import IbMessage from '../../../../components/common/IbMessage';
import IbBadge from '../../../../components/common/IbBadge';
import IbIcon, { IbIconName } from '../../../../components/common/IbIcon';
import { formatDateTimeAdaptive } from '../../../../../utils/stringUtil';
import { ActivityDirection, ConversationModel } from '../../../../../../api';
import { TEXT_FORMAT_TYPES } from '../../../../../constants';

import {
  MAIN_CLASS_NAME,
  MESSAGE_CLASS_NAME,
  MESSAGE_FOOTER_BADGE_CLASS_NAME,
  MESSAGE_FOOTER_CLASS_NAME,
  MESSAGE_FOOTER_TEXT_CLASS_NAME,
  MESSAGE_HEADER_CLASS_NAME,
  MESSAGE_HEADER_TIME_CLASS_NAME,
  MESSAGE_HEADER_TITLE_CLASS_NAME,
} from './const';

interface DialogItem {
  isSelected: boolean;
  model: ConversationModel;
}

interface IDialogListItemProps {
  item: DialogItem;
  selectable: boolean;
  onSelection: () => void;
}

const DialogListItem: React.FC<IDialogListItemProps> = ({ item, selectable, onSelection }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={MAIN_CLASS_NAME}>
      <div className={MESSAGE_CLASS_NAME}>
        <div className={MESSAGE_HEADER_CLASS_NAME}>
          {selectable && <IbCheckbox value={item.isSelected} onChange={onSelection} />}
          <IbIcon iconName={item.model.channelId as IbIconName} size={16} />
          <div className={MESSAGE_HEADER_TITLE_CLASS_NAME}>{item.model.userName}</div>
          <div className={MESSAGE_HEADER_TIME_CLASS_NAME}>
            {formatDateTimeAdaptive(item.model.latestMessageOn ?? item.model.startedOn, i18n.language, 'short', true)}
          </div>
        </div>
        <div className={MESSAGE_FOOTER_CLASS_NAME}>
          <div className={MESSAGE_FOOTER_TEXT_CLASS_NAME}>
            <IbTypography.Paragraph disabled type="secondary">
              {item.model.latestMessageDirection === ActivityDirection.Outbound ? `${t('Bot')}:` : undefined}
            </IbTypography.Paragraph>
            <IbMessage
              inline
              content={{ text: item.model.latestMessageText ?? '', type: TEXT_FORMAT_TYPES.markdown }}
              mentionLinksEnabled={false}
              mentions={[]}
            />
          </div>
          <div className={MESSAGE_FOOTER_BADGE_CLASS_NAME}>
            <IbBadge disabled value={item.model.messagesCount} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogListItem;
