import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import './index.less';

import { ActivityDirection, ActivityModel, InboxDirection, VariableType } from '../../../../../../api';
import IbIcon from '../../../../components/common/IbIcon';
import IbTypography from '../../../../components/common/IbTypography';
import { TriggerDescriptions, TriggerNames } from '../../const';

import {
  ARTICLE_CLASS_NAME,
  ARTICLE_FRAME_CLASS_NAME,
  ATTACHMENTS_CLASS_NAME,
  ATTACHMENTS_ITEM_CLASS_NAME,
  DATE_FORMAT,
  FRAME_CLASS_NAME,
  FRAME_TEXT_CLASS_NAME,
  INTENT_CLASS_NAME,
  INTENT_NAME_CLASS_NAME,
  INTENT_SCORE_CLASS_NAME,
  KB_ANSWER_CLASS_NAME,
  KB_ANSWER_DESCRIPTION_CLASS_NAME,
  KB_ANSWER_DIVIDER_CLASS_NAME,
  KB_ANSWER_ITEM_CLASS_NAME,
  KB_ANSWER_ITEM_NAME_CLASS_NAME,
  KB_ANSWER_ITEM_SCORE_CLASS_NAME,
  MAIN_CLASS_NAME,
  SUGGESTED_ACTIONS_CLASS_NAME,
  SUGGESTED_ACTIONS_ITEM_CLASS_NAME,
  SUGGESTED_ACTIONS_ITEM_TEXT_CLASS_NAME,
  TITLE_CLASS_NAME,
  VARIABLE_CLASS_NAME,
  VARIABLE_NAME_CLASS_NAME,
  VARIABLE_VALUE_CLASS_NAME,
} from './const';

interface IMessageInfoProps {
  activity: ActivityModel;
  getRecognizerResult: (activity: ActivityModel) => ActivityModel | undefined;
  getSigmaRecognizerResult: (activity: ActivityModel) => ActivityModel | undefined;
  getScenarioInfo: (activity: ActivityModel) => ActivityModel | undefined;
  getTriggerInfo: (activity: ActivityModel) => ActivityModel | undefined;
}

const MessageInfo: React.FC<IMessageInfoProps> = ({
  activity,
  getRecognizerResult,
  getSigmaRecognizerResult,
  getScenarioInfo,
  getTriggerInfo,
}) => {
  const { t } = useTranslation();
  const recognizerResult = getRecognizerResult(activity);
  const sigmaRecognizerResult = getSigmaRecognizerResult(activity);
  const scenarioStarted = getScenarioInfo(activity);
  const triggerFired = getTriggerInfo(activity);

  const renderMessage = () => (
    <div className={FRAME_CLASS_NAME}>
      <IbTypography>
        <IbTypography.Paragraph type="secondary">
          {activity.properties.direction === InboxDirection.Internal
            ? t('From operator (hidden)') // TODO: пока что сообщениями от оператора считаются только скрытые сообщения
            : activity.direction == ActivityDirection.Inbound
            ? t('From user')
            : t('From bot (markdown)')}
        </IbTypography.Paragraph>
        <IbTypography.Paragraph strong className={FRAME_TEXT_CLASS_NAME}>
          {activity.text || `(${t('Empty message')})`}
        </IbTypography.Paragraph>
        <IbTypography.Paragraph type="secondary">{moment(activity.date).format(DATE_FORMAT)}</IbTypography.Paragraph>
      </IbTypography>
    </div>
  );

  const renderScenarioInfo = () => {
    if (scenarioStarted) {
      return (
        <>
          <div className={ARTICLE_CLASS_NAME}>
            <IbIcon iconName="adjacent-item" size={20} />
            <span>{t('Scenario')}</span>
          </div>
          <div className={ARTICLE_FRAME_CLASS_NAME}>
            <IbTypography>
              <IbTypography.Paragraph strong>{scenarioStarted.text}</IbTypography.Paragraph>
            </IbTypography>
          </div>
        </>
      );
    }
  };

  const renderTriggerInfo = () => {
    if (triggerFired?.text) {
      return (
        <>
          <div className={ARTICLE_CLASS_NAME}>
            <IbIcon iconName="lightning" size={20} />
            <span>{t('Trigger')}</span>
          </div>
          <div className={ARTICLE_FRAME_CLASS_NAME}>
            <IbTypography>
              <IbTypography.Paragraph strong>{TriggerDescriptions[triggerFired.text]}</IbTypography.Paragraph>
            </IbTypography>
          </div>
        </>
      );
    }
  };

  const renderAttachments = () => {
    if (activity.attachments?.length) {
      return (
        <>
          <div className={ARTICLE_CLASS_NAME}>
            <IbIcon iconName="paperclip" size={20} />
            <span>{t('Attachments')}</span>
          </div>
          <div className={ATTACHMENTS_CLASS_NAME}>
            {activity.attachments.map((m) => (
              <div key={m.contentUrl ?? ''} className={ATTACHMENTS_ITEM_CLASS_NAME}>
                <a download href={m.contentUrl ?? ''}>
                  <IbIcon iconName="download" size={16} />
                  <span>{m.name}</span>
                </a>
              </div>
            ))}
          </div>
        </>
      );
    }
  };

  const renderSuggestedActions = () => {
    if (activity.suggestedActions?.length) {
      return (
        <>
          <div className={ARTICLE_CLASS_NAME}>
            <IbIcon iconName="vertical-tidy-up" size={20} />
            <span>{t('Buttons')}</span>
          </div>
          <div className={SUGGESTED_ACTIONS_CLASS_NAME}>
            {activity.suggestedActions.map((m) => (
              <div key={m} className={SUGGESTED_ACTIONS_ITEM_CLASS_NAME}>
                <div className={SUGGESTED_ACTIONS_ITEM_TEXT_CLASS_NAME}>{m}</div>
              </div>
            ))}
          </div>
        </>
      );
    }
  };

  const renderIntents = () => {
    if (recognizerResult?.value?.Intents?.length) {
      return (
        <>
          <div className={ARTICLE_CLASS_NAME}>
            <IbIcon iconName="adjacent-item" size={20} />
            <span>{t('Recognized intents')}</span>
          </div>
          {recognizerResult.value.Intents.map((m: { Id: string; Name: string; Score: number }) => (
            <div key={m.Id} className={INTENT_CLASS_NAME}>
              <div className={INTENT_NAME_CLASS_NAME}>{m.Name}</div>
              <div className={INTENT_SCORE_CLASS_NAME}>{m.Score.toFixed(3)}</div>
            </div>
          ))}
        </>
      );
    }
  };

  const renderKbAnswers = () => {
    if (sigmaRecognizerResult?.value?.results?.length) {
      return (
        <>
          <div className={ARTICLE_CLASS_NAME}>
            <IbIcon iconName="book-one" size={20} />
            <span>{t('Knowledge base')}</span>
          </div>
          {sigmaRecognizerResult?.value.results.map(
            (result: { score: number; answer: string; question: string; questions: Array<string> }, index: number) => (
              <div key={index} className={KB_ANSWER_CLASS_NAME}>
                <div className={KB_ANSWER_ITEM_CLASS_NAME}>
                  <div className={KB_ANSWER_ITEM_NAME_CLASS_NAME}>
                    {!result.question
                      ? result.questions.map((question: string, index: number) => <div key={index}>{question}</div>)
                      : result.question}
                  </div>
                  <div className={KB_ANSWER_ITEM_SCORE_CLASS_NAME}>{result.score.toFixed(3)}</div>
                </div>
                <div className={KB_ANSWER_DIVIDER_CLASS_NAME} />
                <div className={KB_ANSWER_DESCRIPTION_CLASS_NAME}>{result.answer}</div>
              </div>
            )
          )}
        </>
      );
    }
  };

  const renderVariableValue = (type: VariableType, value: unknown) => {
    switch (type) {
      case 'Boolean':
        return <>{value ? 'Да' : 'Нет'}</>;
      case 'Number':
      case 'String':
        return <>{value as string}</>;
      case 'ComplexObject':
        return <pre>{JSON.stringify(value, null, 2)}</pre>;
      case 'DateTime':
        return <>{new Date((value as Array<{ Value: string; Timex: string }>)[0].Timex).toLocaleString()}</>;
      case 'PersonName': {
        const fullName = value as { first: string; last: string; middle: string };
        return (
          <>
            {fullName.last} {fullName.first} {fullName.middle}
          </>
        );
      }
      case 'File':
        return (
          <a download href={(value as { contentUrl: string }).contentUrl ?? ''}>
            <IbIcon iconName="download" size={16} />
            {(value as { name: string }).name ?? ''}
          </a>
        );
    }
  };

  const renderExternalEventVariables = () => {
    if (triggerFired?.text == TriggerNames.ExternalEvent && triggerFired?.value?.externalEventRawVariables) {
      return (
        <>
          <div className={ARTICLE_CLASS_NAME}>
            <IbIcon iconName="share-three" size={20} />
            <span>{t('External event variables')}</span>
          </div>
          <div className={ARTICLE_FRAME_CLASS_NAME}>
            {Object.values<{ Name: string; Type: VariableType; Value: unknown }>(
              triggerFired.value.externalEventRawVariables
            ).map((value, index) => {
              if (value.Name === undefined) return;
              return (
                <div key={index} className={VARIABLE_CLASS_NAME}>
                  {value.Type && <span className={VARIABLE_NAME_CLASS_NAME}>{value.Name}</span>}
                  <div className={VARIABLE_VALUE_CLASS_NAME}>{renderVariableValue(value.Type, value.Value)}</div>
                </div>
              );
            })}
          </div>
        </>
      );
    }
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      <div className={TITLE_CLASS_NAME}>{t('About message')}</div>
      {renderMessage()}
      {renderScenarioInfo()}
      {renderTriggerInfo()}
      {renderAttachments()}
      {renderSuggestedActions()}
      {renderIntents()}
      {renderKbAnswers()}
      {renderExternalEventVariables()}
    </div>
  );
};

export default MessageInfo;
