import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import './index.less';

import { Channels, DIALOGS_EXPORT_FINISHED } from '../../../../../constants';
import { ChannelNames, Elma365ChannelIdentifiers } from '../../const';
import { renderMessageCount } from '../../../../../utils/stringUtil';
import { ConversationModel, ConversationStatus } from '../../../../../../api';
import { conversationExportApi } from '../../../../../apis';
import { hubConnections } from '../../../../../utils/socketsUtil';
import { getErrorMessage } from '../../../../../utils/errorUtils';
import IbProgressStatusModal, { IbProgressStatusModalStatus } from '../../../../components/IbProgressStatusModal';
import IbTypography from '../../../../components/common/IbTypography';
import IbTooltip from '../../../../components/common/IbTooltip';
import IbButton from '../../../../components/common/IbButton';
import IbAvatar from '../../../../components/common/IbAvatar';
import IbIcon, { IbIconName } from '../../../../components/common/IbIcon';

import {
  BUTTONS_CLASS_NAME,
  CHANNEL_NAME_CLASS_NAME,
  CONTENT_CLASS_NAME,
  DATE_FORMAT,
  FRAME_CLASS_NAME,
  MAIN_CLASS_NAME,
  SESSION_DATE_FORMAT,
  TITLE_CLASS_NAME,
} from './const';

interface IDialogExport {
  preparing: boolean;
  requestId: string;
  errorMessage: string;
  fileUrl: string;
}

const dialogExportDefaultValue: IDialogExport = {
  preparing: false,
  requestId: '',
  errorMessage: '',
  fileUrl: '',
};

interface IDialogInfoProps {
  conversation: ConversationModel;
}

const DialogInfo: React.FC<IDialogInfoProps> = ({ conversation }) => {
  const { t } = useTranslation();
  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const [dialogExport, setDialogExport] = useState(dialogExportDefaultValue);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const exportStatus = dialogExport.preparing
    ? IbProgressStatusModalStatus.InProgress
    : dialogExport.errorMessage
    ? IbProgressStatusModalStatus.Error
    : IbProgressStatusModalStatus.Success;
  const exportTitle = dialogExport.preparing
    ? t('Dialog export in progress')
    : dialogExport.errorMessage
    ? t('An error occurred while exporting the dialog')
    : t('Dialog export was successful');

  const members = Object.values<{ nickName: string; id: string }>(conversation?.properties?.members ?? []);

  const closeExportReportModal = () => {
    setExportModalVisible(false);
    setDialogExport(dialogExportDefaultValue);
  };

  const runExportDialogsAsync = async () => {
    setDialogExport({
      ...dialogExportDefaultValue,
      preparing: true,
    });
    setExportModalVisible(true);

    try {
      const response = await conversationExportApi.runConversationExport(conversation.id);

      setDialogExport({
        ...dialogExportDefaultValue,
        requestId: response.data.requestId,
        preparing: true,
      });
    } catch (e) {
      setDialogExport({
        ...dialogExportDefaultValue,
        errorMessage: getErrorMessage(e as Error),
      });
    }
  };

  const dialogsExportEventHandler = (args: { requestId: string; fileUrl: string; errorMessage: string }) => {
    if (dialogExport.requestId !== args?.requestId || !dialogExport.preparing) {
      return;
    }

    const fileUrl = args?.fileUrl || '';
    setDialogExport({
      ...dialogExport,
      preparing: false,
      errorMessage: args?.errorMessage || '',
      fileUrl,
    });

    if (fileUrl) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(fileUrl, '_self');
    }
  };

  const subscribeToDialogsExportEvents = () => {
    if (!dialogExport.requestId || !conn) return;

    conn.on(DIALOGS_EXPORT_FINISHED, dialogsExportEventHandler);

    return () => {
      conn.off(DIALOGS_EXPORT_FINISHED, dialogsExportEventHandler);
    };
  };
  useEffect(subscribeToDialogsExportEvents, [conn, dialogExport.requestId]);

  const onChatDeskOpenButtonClick = () => {
    const serviceUrl = new URL(conversation.serviceUrl);
    const sessionId = conversation.properties.channelData.elma365.session.id;
    const chatDeskUrl = `${serviceUrl.origin}/_lines/_sessions/detail/${sessionId}`;
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(chatDeskUrl, '_blank');
  };

  const onDialogExportButtonClick = () => runExportDialogsAsync().finally();

  const onExportModalClose = () => closeExportReportModal();

  const renderDialogExportModalContent = () => {
    switch (exportStatus) {
      case IbProgressStatusModalStatus.InProgress:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{t('This may take some time')}</IbTypography.Paragraph>
            <IbTypography.Paragraph>{t('Please wait')}</IbTypography.Paragraph>
          </IbTypography>
        );
      case IbProgressStatusModalStatus.Success:
        return <a href={dialogExport.fileUrl}>{t('Download link')}</a>;
      case IbProgressStatusModalStatus.Error:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{dialogExport.errorMessage}</IbTypography.Paragraph>
          </IbTypography>
        );
    }
  };

  return (
    <>
      <div className={MAIN_CLASS_NAME}>
        <div className={TITLE_CLASS_NAME}>{t('About conversation')}</div>
        {conversation.status === ConversationStatus.Closed && (
          <div className={FRAME_CLASS_NAME}>
            <IbTypography>
              <IbTypography.Paragraph strong>
                {t('Conversation closed')} {moment(conversation.finishedOn).format(SESSION_DATE_FORMAT)}
              </IbTypography.Paragraph>
            </IbTypography>
          </div>
        )}
        <div className={CONTENT_CLASS_NAME}>
          <IbAvatar iconName={conversation.channelId as IbIconName} size="small" />
          <IbTypography>
            <IbTypography.Paragraph className={CHANNEL_NAME_CLASS_NAME}>
              {ChannelNames.find((c) => c.value === conversation.channelId)?.label}
              {conversation.channelId === Channels.ELMA365 &&
                conversation.properties?.channelData?.originalChannelId &&
                conversation.properties?.channelData?.elma365?.session?.id && (
                  <>
                    {' '}
                    ({Elma365ChannelIdentifiers[conversation.properties.channelData.originalChannelId]})
                    <IbTooltip title={conversation.properties.channelData.elma365.session.id}>
                      <IbIcon iconName="info" size={14} />
                    </IbTooltip>
                  </>
                )}
            </IbTypography.Paragraph>
            <IbTypography.Paragraph light type="descriptor">
              {t('Created')} {moment(conversation.startedOn).format(DATE_FORMAT)}
            </IbTypography.Paragraph>
          </IbTypography>
        </div>
        <div className={CONTENT_CLASS_NAME}>
          <IbAvatar iconName="comment" size="small" />
          <IbTypography>
            <IbTypography.Paragraph>{renderMessageCount(conversation.messagesCount ?? 0)}</IbTypography.Paragraph>
            <IbTypography.Paragraph light type="descriptor">
              {t('Last')} {moment(conversation.latestMessageOn).format(DATE_FORMAT)}
            </IbTypography.Paragraph>
          </IbTypography>
        </div>
        <div className={CONTENT_CLASS_NAME}>
          <IbAvatar iconName="user" size="small" />
          <IbTypography>
            <IbTypography.Paragraph>{conversation.userName}</IbTypography.Paragraph>
            {!!members.length && (
              <IbTypography.Paragraph light type="descriptor">
                {conversation.channelId === Channels.TELEGRAM && members[0].nickName ? (
                  <a href={`https://t.me/${members[0].nickName}`} rel="noreferrer" target="_blank">
                    @{members[0].nickName}
                  </a>
                ) : (
                  (conversation.channelId === Channels.VIBER || conversation.channelId === Channels.TELEGRAM) &&
                  `User ID: ${members[0].id}`
                )}
              </IbTypography.Paragraph>
            )}
          </IbTypography>
        </div>
        <div className={BUTTONS_CLASS_NAME}>
          {conversation.channelId === Channels.ELMA365 && (
            <IbButton type="secondary" onClick={onChatDeskOpenButtonClick}>
              {t('Open ELMA365 session')}
            </IbButton>
          )}
          <IbButton type="secondary" onClick={onDialogExportButtonClick}>
            {t('Download transcript')}
          </IbButton>
        </div>
      </div>
      <IbProgressStatusModal
        header={exportTitle}
        status={exportStatus}
        visible={exportModalVisible}
        onCancel={onExportModalClose}
      >
        {renderDialogExportModalContent()}
      </IbProgressStatusModal>
    </>
  );
};

export default DialogInfo;
