import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbIcon from '../../../../components/common/IbIcon';
import IbButton from '../../../../components/common/IbButton';
import { ActivityModel } from '../../../../../../api';

import { END_CLASS_NAME, ERROR_CLASS_NAME, MAIN_CLASS_NAME } from './const';

interface IErrorOccuredProps {
  activity: ActivityModel;
  onSelect: (activity: ActivityModel) => void;
}

const ErrorOccured: React.FC<IErrorOccuredProps> = ({ activity, onSelect }) => {
  const { t } = useTranslation();
  const classes = [MAIN_CLASS_NAME, ERROR_CLASS_NAME, END_CLASS_NAME];
  const onShowDetails = () => onSelect(activity);
  return (
    <div className={classes.join(' ')}>
      <IbIcon iconName="attention" size={16} />
      <span>{t('An error occurred while the bot was running')}</span>
      <IbButton type="link" onClick={onShowDetails}>
        {t('Details')}
      </IbButton>
    </div>
  );
};

export default ErrorOccured;
