import merge from 'lodash/merge';
import { defaultSchema } from 'rehype-sanitize';

import { ConversationStatus } from '../../../../api';
import { Channels } from '../../../constants';

export const MAIN_CLASS_NAME = 'ib-dialogs-page';
export const CONTENT_ROW_CLASS_NAME = `${MAIN_CLASS_NAME}__content-row`;
export const HEADER_CLASS_NAME = `${MAIN_CLASS_NAME}__header`;
export const HEADER_BACK_BUTTON_CLASS_NAME = `${HEADER_CLASS_NAME}__back-button`;
export const HEADER_CONTENT_CLASS_NAME = `${HEADER_CLASS_NAME}__content`;
export const HEADER_CONTENT_TITLE_CLASS_NAME = `${HEADER_CONTENT_CLASS_NAME}__title`;
export const HEADER_CONTENT_DESCRIPTION_CLASS_NAME = `${HEADER_CONTENT_CLASS_NAME}__description`;
export const HEADER_UPDATE_BUTTON_CLASS_NAME = `${HEADER_CLASS_NAME}__update-button`;
export const RESIZABLE_COLUMN_CLASS_NAME = 'ib-column-resizable';
export const DIALOG_LIST_CLASS_NAME = `${CONTENT_ROW_CLASS_NAME}__dialog-list`;

export const markdownSchema = merge(defaultSchema, { tagNames: ['u'] });

export const ChannelNames = [
  { value: Channels.DIRECTLINE, label: 'Веб-чат' },
  { value: Channels.TELEGRAM, label: 'Telegram' },
  { value: Channels.VIBER, label: 'Viber' },
  { value: Channels.WHATSAPP, label: 'WhatsApp' },
  { value: Channels.ELMA365, label: 'ELMA365' },
  { value: Channels.EMULATOR, label: 'Эмулятор' },
  { value: Channels.LIVECHAT, label: 'Live Chat' },
];

export const Elma365ChannelIdentifiers = {
  telegram: 'Telegram',
  telegram_bot: 'Telegram Bot',
  viberbot: 'Viber',
  instagram: 'Instagram',
  vkontakte: 'VK',
  whatsapp: 'WhatsApp',
  quick: 'Линия',
} as { [x: string]: string };

export const ConversationStatusNames = [
  { value: ConversationStatus.Active, label: 'Активная' },
  { value: ConversationStatus.Closed, label: 'Закрытая' },
  { value: ConversationStatus.Operator, label: 'Переведена на оператора' },
];

export const TriggerNames = {
  Start: 'start-trigger',
  Terminal: 'terminal-trigger',
  OmegaIntent: 'intent-trigger',
  SigmaIntent: 'knowledge-base-answer-trigger',
  UnknownIntent: 'unknown-intent-trigger',
  Transition: 'transition-trigger',
  MenuButton: 'menu-button-trigger',
  Command: 'command-trigger',
  ExternalEvent: 'external-event-trigger',
};

export const ActivityEventNames = {
  ScenarioStarted: 'scenario-started',
  MenuItemSelected: 'menu-item-selected',
  OmegaRecognizer: 'OmegaRecognizer',
  SigmaRecognizer: 'SigmaClient',
  PromptAccepted: 'promptAccepted',
  TransferToOperatorInitiated: 'TransferToOperatorInitiated',
  ErrorOccured: 'error',
  TriggerFired: 'trigger-fired',
  ScriptExecuted: 'ScriptExecutorService_RunScript',
};

export const TriggerDescriptions = {
  [TriggerNames.Start]: 'Старт бота',
  [TriggerNames.Terminal]: 'Триггер завершения',
  [TriggerNames.OmegaIntent]: 'Интент',
  [TriggerNames.SigmaIntent]: 'База знаний',
  [TriggerNames.UnknownIntent]: 'Неизвестный интент',
  [TriggerNames.Transition]: 'Переход из другого сценария',
  [TriggerNames.MenuButton]: 'Кнопка меню',
  [TriggerNames.Command]: 'Команда',
  [TriggerNames.ExternalEvent]: 'Внешнее событие',
};
