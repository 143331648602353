export const MAIN_CLASS_NAME = 'ib-dialog-list';
export const SEARCH_CLASS_NAME = `${MAIN_CLASS_NAME}__search`;
export const SEARCH_FILTER_BUTTON_CLASS_NAME = `${SEARCH_CLASS_NAME}__filter-button`;
export const LIST_CLASS_NAME = `${MAIN_CLASS_NAME}__list`;
export const EXPORT_CLASS_NAME = `${MAIN_CLASS_NAME}__export`;
export const EXPORT_COUNT_CLASS_NAME = `${EXPORT_CLASS_NAME}__count`;
export const EXPORT_BUTTONS_CLASS_NAME = `${EXPORT_CLASS_NAME}__buttons`;
export const FILTER_MENU_CLASS_NAME = `${MAIN_CLASS_NAME}__filter-menu`;
export const FILTER_MENU_BUTTONS_CLASS_NAME = `${FILTER_MENU_CLASS_NAME}__buttons`;

export const DIALOG_ITEMS_SCROLL_ID = 'ib-dialog-list-scroll';
export const DATE_LOCAL_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const SEARCH_DELAY = 200; //ms
export const CONVERSATION_SEARCH_PAGE_SIZE = 50;
export const MENU_ICON_SIZE = 20;

export const CHANNEL_ID_PARAM = 'channelId';
export const CONVERSATION_STATUS_PARAM = 'conversationStatus';
export const START_FROM_DATE_PARAM = 'startFromDate';
export const START_TO_DATE_PARAM = 'startToDate';
export const LATEST_MESSAGE_FROM_DATE_PARAM = 'latestMessageFromDate';
export const LATEST_MESSAGE_TO_DATE_PARAM = 'latestMessageToDate';
export const CONVERSATION_ID_PARAM = 'conversationId';
export const SEARCH_PARAM = 'query';
