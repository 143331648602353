import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbButton from '../../../../components/common/IbButton';
import IbIcon from '../../../../components/common/IbIcon';
import { ActivityAttachment } from '../../../../../../api';

import { BOT_SELECTOR_NAME, END_CLASS_NAME, ITEM_CLASS_NAME, MAIN_CLASS_NAME, MAX_VISIBLE_COUNT } from './const';

interface IAttachmentProps {
  attachments: ActivityAttachment[];
  role: string;
}

const Attachments: React.FC<IAttachmentProps> = ({ attachments, role }) => {
  const { t } = useTranslation();
  const [visibleAttachments, setVisibleAttachments] = useState(attachments.slice(0, MAX_VISIBLE_COUNT));
  const [canExpand, setCanExpand] = useState(attachments.length > MAX_VISIBLE_COUNT);
  const remainingAttachments = attachments.slice(MAX_VISIBLE_COUNT);

  const onExpand = () => {
    setVisibleAttachments([...visibleAttachments, ...remainingAttachments]);
    setCanExpand(false);
  };

  const onCollapse = () => {
    setVisibleAttachments(visibleAttachments.slice(0, MAX_VISIBLE_COUNT));
    setCanExpand(true);
  };

  return (
    <>
      <div className={`${MAIN_CLASS_NAME} ${role == BOT_SELECTOR_NAME ? END_CLASS_NAME : ''}`}>
        {visibleAttachments.map((m) => (
          <div key={m.contentUrl ?? ''} className={ITEM_CLASS_NAME}>
            <a download href={m.contentUrl ?? ''}>
              <IbIcon iconName="paperclip" size={16} />
              <span>{m.name}</span>
            </a>
          </div>
        ))}
        {canExpand && (
          <IbButton type="link" onClick={onExpand}>
            {`+ ${t('More_lowercase')} ${remainingAttachments.length}`}
          </IbButton>
        )}
        {!canExpand && attachments.length > MAX_VISIBLE_COUNT && (
          <IbButton type="link" onClick={onCollapse}>
            {t('Collapse')}
          </IbButton>
        )}
      </div>
    </>
  );
};

export default Attachments;
