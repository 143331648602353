import React from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbIcon from '../../../../components/common/IbIcon';

import { MAIN_CLASS_NAME, SESSION_DATE_FORMAT, TEXT_CLASS_NAME, TEXT_CLOSED_CLASS_NAME } from './const';

interface ISessionStatusProps {
  timestamp: string;
  isClosed?: boolean;
}

const SessionStatus: React.FC<ISessionStatusProps> = ({ timestamp, isClosed }) => {
  const { t } = useTranslation();
  return (
    <div className={MAIN_CLASS_NAME}>
      <div className={`${TEXT_CLASS_NAME} ${isClosed ? TEXT_CLOSED_CLASS_NAME : ''}`}>
        {isClosed ? (
          <>
            <IbIcon iconName="lock" size={12} />
            <span>
              {t('Conversation closed')} {moment(timestamp).format(SESSION_DATE_FORMAT)}
            </span>
          </>
        ) : (
          <span>
            {t('Conversation started')} {moment(timestamp).format(SESSION_DATE_FORMAT)}
          </span>
        )}
      </div>
    </div>
  );
};

export default SessionStatus;
