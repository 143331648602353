import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbIcon from '../../../../components/common/IbIcon';

import { END_CLASS_NAME, MAIN_CLASS_NAME } from './const';

const TransitionTrigger: React.FC = () => {
  const { t } = useTranslation();
  const classes = [MAIN_CLASS_NAME, END_CLASS_NAME];
  return (
    <div className={classes.join(' ')}>
      <IbIcon iconName="s-turn-left" size={16} />
      <span>{t('Transition from other scenario')}</span>
    </div>
  );
};

export default TransitionTrigger;
