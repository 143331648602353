import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbIcon from '../../../../components/common/IbIcon';

import { END_CLASS_NAME, MAIN_CLASS_NAME } from './const';

interface IScenarioStartedProps {
  name: string | undefined | null;
}

const ScenarioStarted: React.FC<IScenarioStartedProps> = ({ name }) => {
  const { t } = useTranslation();
  const classes = [MAIN_CLASS_NAME, END_CLASS_NAME];
  return (
    <div className={classes.join(' ')}>
      <IbIcon iconName="play" size={16} />
      <span>
        {t('Scenario started')} «{name}»
      </span>
    </div>
  );
};

export default ScenarioStarted;
