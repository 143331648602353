import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Table } from 'antd';

import './index.less';

import { ConversationsClosedPerDayModel, ConversationsClosedPerScenarioModel } from '../../../../../api';
import { analyticsReportsApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { ANALYTICS_DATE_FORMATS } from '../../../const';
import { formatAnalyticsDate, formatAnalyticsDateCompact } from '../../../utils/render';
import { AnalyticsDateFilter, AnalyticsViewModes } from '../../../types';
import SbTable from '../../common/SbTable';
import SbBar from '../../common/SbBar';

const CHART_SETTINGS = {
  barColors: {
    byOperatorCount: '#E06E53',
    byScenarioActionCount: '#F6BD4C',
    timedOutCount: '#7AD0FB',
    conversationRestartedCount: '#5773B4',
    totalCount: '#74C8B8',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipContent: React.FC<any> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload as ConversationsClosedPerDayModel;
  if (data.totalCount <= 0) {
    return null;
  }

  return (
    <div className="tooltip-content">
      <div>
        Дата: <b>{formatAnalyticsDate(label)}</b>
      </div>
      <div>
        Закрыто оператором: <b>{data.byOperatorCount}</b>
      </div>
      <div>
        Закрыто элементом сценария: <b>{data.byScenarioActionCount}</b>
      </div>
      <div>
        Покинуто: <b>{data.timedOutCount}</b>
      </div>
      <div>
        Закрыто из-за рестарта беседы: <b>{data.conversationRestartedCount}</b>
      </div>
      <div>
        Всего закрыто: <b>{data.totalCount}</b>
      </div>
    </div>
  );
};

interface ISbConversationsClosedAnalyticsProps {
  isAnimationActive: boolean;
  agentStageId: string;
  filter: AnalyticsDateFilter;
  viewMode: AnalyticsViewModes;
}

const SbConversationsClosedAnalytics: React.FC<ISbConversationsClosedAnalyticsProps> = ({
  isAnimationActive,
  agentStageId,
  filter,
  viewMode,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [eventsPerDayList, setEventsPerDayList] = useState<ConversationsClosedPerDayModel[]>([]);
  const [eventsPerScenarioList, setEventsPerScenarioList] = useState<ConversationsClosedPerScenarioModel[]>([]);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const formattedFromDate = filter.fromDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse);
      const formattedToDate = filter.toDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse);

      const eventsPerDayResponse = await analyticsReportsApi.getConversationsClosedPerDayList(
        agentStageId,
        formattedFromDate,
        formattedToDate
      );
      const eventsPerScenarioResponse = await analyticsReportsApi.getConversationsClosedPerScenarioList(
        agentStageId,
        formattedFromDate,
        formattedToDate
      );

      setEventsPerDayList(eventsPerDayResponse.data);
      setEventsPerScenarioList(eventsPerScenarioResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных аналитики по закрытию бесед',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [agentStageId, filter]);

  const countMax = Math.max(...eventsPerScenarioList.map((e) => e.totalCount));

  const tableColumns = [
    {
      title: 'Сценарий',
      dataIndex: 'scenarioName',
      key: 'scenarioName',
      render: (_: string, e: ConversationsClosedPerScenarioModel) => e.scenarioName,
    },
    {
      title: 'Закрыто оператором',
      dataIndex: 'byOperatorCount',
      key: 'byOperatorCount',
      render: (_: string, e: ConversationsClosedPerScenarioModel) => (
        <div className="sb-analytics-card__content__block__content_with-table__table-cell">
          <span>
            <b>{e.byOperatorCount}</b>
          </span>
          <SbBar color={CHART_SETTINGS.barColors.byOperatorCount} maxValue={countMax} value={e.byOperatorCount} />
        </div>
      ),
    },
    {
      title: 'Закрыто элементом сценария',
      dataIndex: 'byScenarioActionCount',
      key: 'byScenarioActionCount',
      render: (_: string, e: ConversationsClosedPerScenarioModel) => (
        <div className="sb-analytics-card__content__block__content_with-table__table-cell">
          <span>
            <b>{e.byScenarioActionCount}</b>
          </span>
          <SbBar
            color={CHART_SETTINGS.barColors.byScenarioActionCount}
            maxValue={countMax}
            value={e.byScenarioActionCount}
          />
        </div>
      ),
    },
    {
      title: 'Покинуто',
      dataIndex: 'timedOutCount',
      key: 'timedOutCount',
      render: (_: string, e: ConversationsClosedPerScenarioModel) => (
        <div className="sb-analytics-card__content__block__content_with-table__table-cell">
          <span>
            <b>{e.timedOutCount}</b>
          </span>
          <SbBar color={CHART_SETTINGS.barColors.timedOutCount} maxValue={countMax} value={e.timedOutCount} />
        </div>
      ),
    },
    {
      title: 'Закрыто из-за рестарта беседы',
      dataIndex: 'conversationRestartedCount',
      key: 'conversationRestartedCount',
      render: (_: string, e: ConversationsClosedPerScenarioModel) => (
        <div className="sb-analytics-card__content__block__content_with-table__table-cell">
          <span>
            <b>{e.conversationRestartedCount}</b>
          </span>
          <SbBar
            color={CHART_SETTINGS.barColors.conversationRestartedCount}
            maxValue={countMax}
            value={e.conversationRestartedCount}
          />
        </div>
      ),
    },
    {
      title: 'Всего закрыто',
      dataIndex: 'totalCount',
      key: 'totalCount',
      render: (_: string, e: ConversationsClosedPerScenarioModel) => (
        <div className="sb-analytics-card__content__block__content_with-table__table-cell">
          <span>
            <b>{e.totalCount}</b>
          </span>
          <SbBar color={CHART_SETTINGS.barColors.totalCount} maxValue={countMax} value={e.totalCount} />
        </div>
      ),
    },
  ];

  return (
    <div className="sb-conversations-closed-analytics">
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Закрытые беседы</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content ${
            viewMode === AnalyticsViewModes.TABLE ? 'sb-analytics-card__content__block__content_with-table' : ''
          } ${loading ? 'sb-analytics-card__content__block__content_loading' : ''}`}
        >
          {viewMode == AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                {
                  title: 'Дата',
                  dataIndex: 'date',
                  key: 'date',
                  render: (_, record) => formatAnalyticsDate(record.date),
                },
                { title: 'Закрыто оператором', dataIndex: 'byOperatorCount', key: 'byOperatorCount' },
                {
                  title: 'Закрыто элементом сценария',
                  dataIndex: 'byScenarioActionCount',
                  key: 'byScenarioActionCount',
                },
                { title: 'Покинуто', dataIndex: 'timedOutCount', key: 'timedOutCount' },
                {
                  title: 'Закрыто из-за рестарта беседы',
                  dataIndex: 'conversationRestartedCount',
                  key: 'conversationRestartedCount',
                },
                { title: 'Всего закрыто', dataIndex: 'totalCount', key: 'totalCount' },
              ]}
              dataSource={eventsPerDayList}
              pagination={false}
            />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <BarChart data={eventsPerDayList}>
                <XAxis
                  dataKey={(e: ConversationsClosedPerDayModel) => e.date}
                  tickFormatter={formatAnalyticsDateCompact}
                />
                <YAxis tickCount={3} />
                <Tooltip content={<TooltipContent />} cursor={false} isAnimationActive={false} />
                <Legend iconType="circle" />
                <Bar
                  dataKey={(e: ConversationsClosedPerDayModel) => e.byOperatorCount}
                  fill={CHART_SETTINGS.barColors.byOperatorCount}
                  isAnimationActive={isAnimationActive}
                  name="Закрыто оператором"
                />
                <Bar
                  dataKey={(e: ConversationsClosedPerDayModel) => e.byScenarioActionCount}
                  fill={CHART_SETTINGS.barColors.byScenarioActionCount}
                  isAnimationActive={isAnimationActive}
                  name="Закрыто элементом сценария"
                />
                <Bar
                  dataKey={(e: ConversationsClosedPerDayModel) => e.timedOutCount}
                  fill={CHART_SETTINGS.barColors.timedOutCount}
                  isAnimationActive={isAnimationActive}
                  name="Покинуто"
                />
                <Bar
                  dataKey={(e: ConversationsClosedPerDayModel) => e.conversationRestartedCount}
                  fill={CHART_SETTINGS.barColors.conversationRestartedCount}
                  isAnimationActive={isAnimationActive}
                  name="Закрыто из-за рестарта беседы"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Закрытые беседы по сценариям</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content sb-analytics-card__content__block__content_with-table  ${
            loading ? 'sb-analytics-card__content__block__content_loading' : ''
          }`}
        >
          {viewMode === AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                { title: 'Сценарий', dataIndex: 'scenarioName', key: 'scenarioName' },
                { title: 'Закрыто оператором', dataIndex: 'byOperatorCount', key: 'byOperatorCount' },
                {
                  title: 'Закрыто элементом сценария',
                  dataIndex: 'byScenarioActionCount',
                  key: 'byScenarioActionCount',
                },
                { title: 'Покинуто', dataIndex: 'timedOutCount', key: 'timedOutCount' },
                {
                  title: 'Закрыто из-за рестарта беседы',
                  dataIndex: 'conversationRestartedCount',
                  key: 'conversationRestartedCount',
                },
                { title: 'Всего закрыто', dataIndex: 'totalCount', key: 'totalCount' },
              ]}
              dataSource={eventsPerScenarioList.sort((a, b) => b.totalCount - a.totalCount)}
              pagination={false}
            />
          ) : (
            <SbTable
              columns={tableColumns}
              dataSource={[...eventsPerScenarioList].sort((a, b) => b.totalCount - a.totalCount)}
              emptyText="Нет данных"
              rowKey="scenarioName"
              scrollEnabled={false}
              tableLayout="fixed"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SbConversationsClosedAnalytics;
