export const MAIN_CLASS_NAME = 'ib-dialog-message-info';
export const FRAME_CLASS_NAME = `${MAIN_CLASS_NAME}__frame`;
export const FRAME_TEXT_CLASS_NAME = `${FRAME_CLASS_NAME}__text`;
export const TITLE_CLASS_NAME = `${MAIN_CLASS_NAME}__title`;
export const ARTICLE_CLASS_NAME = `${MAIN_CLASS_NAME}__article`;
export const ARTICLE_FRAME_CLASS_NAME = `${MAIN_CLASS_NAME}__article-frame`;
export const SUGGESTED_ACTIONS_CLASS_NAME = `${MAIN_CLASS_NAME}__suggested-actions`;
export const SUGGESTED_ACTIONS_ITEM_CLASS_NAME = `${SUGGESTED_ACTIONS_CLASS_NAME}__item`;
export const SUGGESTED_ACTIONS_ITEM_TEXT_CLASS_NAME = `${SUGGESTED_ACTIONS_ITEM_CLASS_NAME}__text`;
export const ATTACHMENTS_CLASS_NAME = `${MAIN_CLASS_NAME}__attachments`;
export const ATTACHMENTS_ITEM_CLASS_NAME = `${ATTACHMENTS_CLASS_NAME}__item`;
export const VARIABLE_CLASS_NAME = `${MAIN_CLASS_NAME}__variable`;
export const VARIABLE_NAME_CLASS_NAME = `${VARIABLE_CLASS_NAME}__name`;
export const VARIABLE_VALUE_CLASS_NAME = `${VARIABLE_CLASS_NAME}__value`;
export const INTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__intent`;
export const INTENT_NAME_CLASS_NAME = `${INTENT_CLASS_NAME}__name`;
export const INTENT_SCORE_CLASS_NAME = `${INTENT_CLASS_NAME}__score`;
export const KB_ANSWER_CLASS_NAME = `${MAIN_CLASS_NAME}__kb-answer`;
export const KB_ANSWER_ITEM_CLASS_NAME = `${KB_ANSWER_CLASS_NAME}__item`;
export const KB_ANSWER_ITEM_NAME_CLASS_NAME = `${KB_ANSWER_ITEM_CLASS_NAME}__name`;
export const KB_ANSWER_ITEM_SCORE_CLASS_NAME = `${KB_ANSWER_ITEM_CLASS_NAME}__score`;
export const KB_ANSWER_DIVIDER_CLASS_NAME = `${KB_ANSWER_CLASS_NAME}__divider`;
export const KB_ANSWER_DESCRIPTION_CLASS_NAME = `${KB_ANSWER_CLASS_NAME}__description`;

export const DATE_FORMAT = 'DD.MM.YYYY в HH:mm';
