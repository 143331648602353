import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbIcon from '../../../../components/common/IbIcon';

import { END_CLASS_NAME, MAIN_CLASS_NAME } from './const';

interface IExternalEventTriggerProps {
  id: string;
}

const ExternalEventTrigger: React.FC<IExternalEventTriggerProps> = ({ id }) => {
  const { t } = useTranslation();
  const classes = [MAIN_CLASS_NAME, END_CLASS_NAME];
  return (
    <div className={classes.join(' ')}>
      <IbIcon iconName="share-three" size={16} />
      <span>
        {t('External event')} «{id}»
      </span>
    </div>
  );
};

export default ExternalEventTrigger;
