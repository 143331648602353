import React from 'react';
import { useTranslation } from 'react-i18next';
import { RetweetOutlined } from '@ant-design/icons';

import './index.less';
import { END_CLASS_NAME, MAIN_CLASS_NAME } from './const';

const TransferToOperatorInitiated: React.FC = () => {
  const { t } = useTranslation();
  const classes = [MAIN_CLASS_NAME, END_CLASS_NAME];
  return (
    <div className={classes.join(' ')}>
      <RetweetOutlined size={16} />
      <span>{t('Transfer to operator initiated')}</span>
    </div>
  );
};

export default TransferToOperatorInitiated;
