import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbButton from '../../../../components/common/IbButton';
import IbIcon from '../../../../components/common/IbIcon';
import { ActivityModel } from '../../../../../../api';

import { END_CLASS_NAME, ERROR_CLASS_NAME, MAIN_CLASS_NAME } from './const';

interface IScriptExecutedProps {
  activity: ActivityModel;
  onSelect: (activity: ActivityModel) => void;
}

const ScriptExecuted: React.FC<IScriptExecutedProps> = ({ activity, onSelect }) => {
  const { t } = useTranslation();
  const classes = [MAIN_CLASS_NAME, END_CLASS_NAME];
  const status = activity.value?.response?.status;
  const onShowDetails = () => onSelect(activity);

  if (status === 'FAILURE') {
    classes.push(ERROR_CLASS_NAME);
  }

  return (
    <div className={classes.join(' ')}>
      <IbIcon iconName="terminal" size={16} />
      <span>{status === 'FAILURE' ? t('Script execution failed') : t('Script executed')}</span>
      <IbButton type="link" onClick={onShowDetails}>
        {t('Details')}
      </IbButton>
    </div>
  );
};

export default ScriptExecuted;
