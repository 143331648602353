import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import { ActivityModel } from '../../../../../../api';
import IbIcon from '../../../../components/common/IbIcon';
import IbButton from '../../../../components/common/IbButton';
import IbTypography from '../../../../components/common/IbTypography';
import ScriptEditor from '../../../../../components/ScenarioEditor/components/actions/ScriptAction/ScriptEditor';

import {
  ARTICLE_CLASS_NAME,
  ARTICLE_FRAME_CLASS_NAME,
  BUTTONS_CLASS_NAME,
  FAILURE_STATUS,
  FRAME_CLASS_NAME,
  FRAME_TEXT_CLASS_NAME,
  LOGS_CLASS_NAME,
  MAIN_CLASS_NAME,
  TITLE_CLASS_NAME,
  VARIABLE_CLASS_NAME,
  VARIABLE_NAME_CLASS_NAME,
  VARIABLE_PREFIX,
  VARIABLE_VALUE_CLASS_NAME,
} from './const';

interface ILogProps {
  level: string;
  message: string;
  retry: number;
  params: string[];
}

interface IScriptExecutionInfoProps {
  activity: ActivityModel;
}

const ScriptExecutionInfo: React.FC<IScriptExecutionInfoProps> = ({ activity }) => {
  const { t } = useTranslation();
  const [editorVisible, setEditorVisible] = useState(false);
  const response = activity.value?.response;
  const status = response?.status;
  const scriptText = activity.value?.originalScriptContent;
  const hasVariables =
    !!Object.entries(response?.output?.variables?.conversation ?? []).length ||
    !!Object.entries(response?.output?.variables?.dialog ?? []).length ||
    !!Object.entries(response?.output?.variables?.user ?? []).length;

  const onShowScriptButtonClick = () => setEditorVisible(true);
  const onScriptEditorClose = () => setEditorVisible(false);

  return (
    <div className={MAIN_CLASS_NAME}>
      <div className={TITLE_CLASS_NAME}>{t('About script')}</div>
      {status === FAILURE_STATUS && (
        <div className={FRAME_CLASS_NAME}>
          <IbTypography>
            <IbTypography.Paragraph type="secondary">{response?.traceback}</IbTypography.Paragraph>
            <IbTypography.Paragraph strong className={FRAME_TEXT_CLASS_NAME}>
              {response?.message}
            </IbTypography.Paragraph>
          </IbTypography>
        </div>
      )}
      <>
        <div className={ARTICLE_CLASS_NAME}>
          <IbIcon iconName="timer" size={20} />
          <span>{t('Execution duration')}</span>
        </div>
        <div className={ARTICLE_FRAME_CLASS_NAME}>
          <IbTypography>
            <IbTypography.Paragraph strong>{activity.value?.duration}</IbTypography.Paragraph>
          </IbTypography>
        </div>
        {hasVariables && (
          <>
            <div className={ARTICLE_CLASS_NAME}>
              <IbIcon iconName="pencil" size={20} />
              <span>{t('Variables')}</span>
            </div>
            <div className={ARTICLE_FRAME_CLASS_NAME}>
              {Object.entries(response?.output?.variables?.dialog).map(([key, value]) => (
                <div key={key} className={VARIABLE_CLASS_NAME}>
                  <span className={VARIABLE_NAME_CLASS_NAME}>{key.substring(VARIABLE_PREFIX.length)}</span>
                  <div className={VARIABLE_VALUE_CLASS_NAME}>
                    <pre>{JSON.stringify(value, null, 2)}</pre>
                  </div>
                </div>
              ))}
              {Object.entries(response?.output?.variables?.conversation).map(([key, value]) => (
                <div key={key} className={VARIABLE_CLASS_NAME}>
                  <span className={VARIABLE_NAME_CLASS_NAME}>{key.substring(VARIABLE_PREFIX.length)}</span>
                  <div className={VARIABLE_VALUE_CLASS_NAME}>
                    <pre>{JSON.stringify(value, null, 2)}</pre>
                  </div>
                </div>
              ))}
              {Object.entries(response?.output?.variables?.user).map(([key, value]) => (
                <div key={key} className={VARIABLE_CLASS_NAME}>
                  <span className={VARIABLE_NAME_CLASS_NAME}>{key.substring(VARIABLE_PREFIX.length)}</span>
                  <div className={VARIABLE_VALUE_CLASS_NAME}>
                    <pre>{JSON.stringify(value, null, 2)}</pre>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {!!response?.output?.logs?.length && (
          <>
            <div className={ARTICLE_CLASS_NAME}>
              <IbIcon iconName="code-one" size={20} />
              <span>{t('Logs')}</span>
            </div>
            <div className={LOGS_CLASS_NAME}>
              {response?.output?.logs?.map((log: ILogProps) => (
                <>
                  <b>{`${log.level} `}</b>
                  {`Retry: ${log.retry}; Message: ${log.message}`}
                  <br />
                  {!!log.params.length && (
                    <>Params: {<pre>{log.params.map((param) => JSON.stringify(param, null, 2))}</pre>}</>
                  )}
                </>
              ))}
            </div>
          </>
        )}
        {scriptText && (
          <div className={BUTTONS_CLASS_NAME}>
            <IbButton type="secondary" onClick={onShowScriptButtonClick}>
              {t('Show script code')}
            </IbButton>
          </div>
        )}
        <ScriptEditor readOnly content={scriptText} visible={editorVisible} onClose={onScriptEditorClose} />
      </>
    </div>
  );
};

export default ScriptExecutionInfo;
