export const MAIN_CLASS_NAME = 'ib-script-execution-info';
export const TITLE_CLASS_NAME = `${MAIN_CLASS_NAME}__title`;
export const FRAME_CLASS_NAME = `${MAIN_CLASS_NAME}__frame`;
export const FRAME_TEXT_CLASS_NAME = `${FRAME_CLASS_NAME}__text`;
export const ARTICLE_CLASS_NAME = `${MAIN_CLASS_NAME}__article`;
export const ARTICLE_FRAME_CLASS_NAME = `${MAIN_CLASS_NAME}__article-frame`;
export const LOGS_CLASS_NAME = `${MAIN_CLASS_NAME}__logs`;
export const VARIABLE_CLASS_NAME = `${MAIN_CLASS_NAME}__variable`;
export const VARIABLE_NAME_CLASS_NAME = `${VARIABLE_CLASS_NAME}__name`;
export const VARIABLE_VALUE_CLASS_NAME = `${VARIABLE_CLASS_NAME}__value`;
export const BUTTONS_CLASS_NAME = `${MAIN_CLASS_NAME}__buttons`;

export const FAILURE_STATUS = 'FAILURE';
export const VARIABLE_PREFIX = 'var_';
