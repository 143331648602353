import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Skeleton, Table } from 'antd';
import moment, { Moment } from 'moment/moment';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { useAsync } from 'react-async-hook';
import { SelectValue } from 'antd/lib/select';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbProgressStatusModal, { IbProgressStatusModalStatus } from '../../../components/IbProgressStatusModal';
import IbContextMenu from '../../../components/common/IbContextMenu';
import IbTypography from '../../../components/common/IbTypography';
import IbPopover from '../../../components/common/IbPopover';
import IbSearch from '../../../components/IbSearch';
import IbButton from '../../../components/common/IbButton';
import IbSelect from '../../../components/common/IbSelect';
import IbIcon from '../../../components/common/IbIcon';
import SbDatePicker from '../../../../simple-bot/components/common/SbDatePicker';
import SbScroll from '../../../../simple-bot/components/common/SbScroll';
import {
  ConversationExportFilterParams,
  ConversationModel,
  ConversationSortDirection,
  ConversationStatus,
} from '../../../../../api';
import { useQuery } from '../../../../utils/urlUtil';
import { hubConnections } from '../../../../utils/socketsUtil';
import { conversationApi, conversationExportApi } from '../../../../apis';
import { getErrorMessage } from '../../../../utils/errorUtils';
import { AlertTypes, DIALOGS_EXPORT_FINISHED } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { isTouchOnlyDevice } from '../../../../utils/browserUtil';
import { ChannelNames, ConversationStatusNames } from '../const';

import DialogListItem from './DialogListItem';
import {
  CHANNEL_ID_PARAM,
  CONVERSATION_ID_PARAM,
  CONVERSATION_SEARCH_PAGE_SIZE,
  CONVERSATION_STATUS_PARAM,
  DATE_FORMAT,
  DATE_LOCAL_FORMAT,
  DIALOG_ITEMS_SCROLL_ID,
  EXPORT_BUTTONS_CLASS_NAME,
  EXPORT_CLASS_NAME,
  EXPORT_COUNT_CLASS_NAME,
  FILTER_MENU_BUTTONS_CLASS_NAME,
  FILTER_MENU_CLASS_NAME,
  LATEST_MESSAGE_FROM_DATE_PARAM,
  LATEST_MESSAGE_TO_DATE_PARAM,
  LIST_CLASS_NAME,
  MAIN_CLASS_NAME,
  MENU_ICON_SIZE,
  SEARCH_CLASS_NAME,
  SEARCH_DELAY,
  SEARCH_FILTER_BUTTON_CLASS_NAME,
  SEARCH_PARAM,
  START_FROM_DATE_PARAM,
  START_TO_DATE_PARAM,
} from './const';

interface IDialogFilter {
  channelId?: string;
  status?: ConversationStatus;
  startDateRange?: [Moment, Moment];
  lastMessageDateRange?: [Moment, Moment];
}

interface IDialogsExport {
  preparing: boolean;
  requestId: string;
  errorMessage: string;
  fileUrl: string;
}

const dialogsExportDefaultValue: IDialogsExport = {
  preparing: false,
  requestId: '',
  errorMessage: '',
  fileUrl: '',
};

interface DialogItem {
  isSelected: boolean;
  model: ConversationModel;
}

interface IDialogListProps {
  agentStageId?: string;
  reloading?: boolean;
}

const DialogList: React.FC<IDialogListProps> = ({ agentStageId, reloading }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const { t } = useTranslation();
  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);
  const { replace } = useHistory();
  const query = useQuery();

  const [conversationsPageIndex, setConversationsPageIndex] = useState(0);
  const [selectedConversationId, setSelectedConversationId] = useState<string>();
  const [conversations, setConversations] = useState([] as DialogItem[]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterPopoverVisible, setFilterPopoverVisible] = useState<boolean>();
  const [filter, setFilter] = useState<IDialogFilter>({});
  const [searchText, setSearchText] = useState('');
  const [dialogsExport, setDialogsExport] = useState(dialogsExportDefaultValue);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [selectable, setSelectable] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const scrollContainer = useRef<HTMLElement>();
  const debouncedSearchText = useDebounce(searchText, SEARCH_DELAY);
  const deferredSearchText = searchText ? debouncedSearchText : '';
  const selectedDialogs = conversations.filter((c) => c.isSelected).map((i) => i.model.id);
  const exportStatus = dialogsExport.preparing
    ? IbProgressStatusModalStatus.InProgress
    : dialogsExport.errorMessage
    ? IbProgressStatusModalStatus.Error
    : IbProgressStatusModalStatus.Success;
  const exportTitle = dialogsExport.preparing
    ? t('Dialogs export in progress')
    : dialogsExport.errorMessage
    ? t('An error occurred while exporting the dialogs')
    : t('Dialogs export was successful');

  const closeDialogsExportModal = () => {
    setExportModalVisible(false);
    setDialogsExport(dialogsExportDefaultValue);
  };

  const runExportDialogsAsync = async (onlySelected: boolean) => {
    if (!agentStageId) {
      return;
    }

    setDialogsExport({
      ...dialogsExportDefaultValue,
      preparing: true,
    });
    setExportModalVisible(true);

    const exportFilter: ConversationExportFilterParams = {};
    if (onlySelected) {
      exportFilter.ids = selectedDialogs;
    } else {
      exportFilter.agentStageId = agentStageId;
      exportFilter.status = filter.status;
      exportFilter.channelId = filter.channelId;
      exportFilter.startFromDate = filter.startDateRange?.[0].format(DATE_FORMAT);
      exportFilter.startToDate = filter.startDateRange?.[1].format(DATE_FORMAT);
      exportFilter.latestMessageFromDate = filter.lastMessageDateRange?.[0].format(DATE_FORMAT);
      exportFilter.latestMessageToDate = filter.lastMessageDateRange?.[1].format(DATE_FORMAT);
    }

    try {
      const response = await conversationExportApi.runConversationExportByFilter(exportFilter);
      setDialogsExport({
        ...dialogsExportDefaultValue,
        requestId: response.data.requestId,
        preparing: true,
      });
    } catch (e) {
      setDialogsExport({
        ...dialogsExportDefaultValue,
        errorMessage: getErrorMessage(e as Error),
      });
    }
  };

  const dialogsExportEventHandler = (args: { requestId: string; fileUrl: string; errorMessage: string }) => {
    if (dialogsExport.requestId !== args?.requestId || !dialogsExport.preparing) {
      return;
    }

    const fileUrl = args?.fileUrl || '';
    setDialogsExport({
      ...dialogsExport,
      preparing: false,
      errorMessage: args?.errorMessage || '',
      fileUrl,
    });

    if (fileUrl) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(fileUrl, '_self');
    }
  };

  const subscribeToDialogsExportEvents = () => {
    if (!dialogsExport.requestId || !conn) return;

    conn.on(DIALOGS_EXPORT_FINISHED, dialogsExportEventHandler);

    return () => {
      conn.off(DIALOGS_EXPORT_FINISHED, dialogsExportEventHandler);
    };
  };
  useEffect(subscribeToDialogsExportEvents, [conn, dialogsExport.requestId]);

  const loadDialogs = async (loadMore: boolean, filter: IDialogFilter) => {
    if (!agentStageId) return;

    setLoading(true);
    try {
      const sorting = ConversationSortDirection.FinishedOnDescending;
      const conversationsResponse = await conversationApi.searchConversations(
        filter.channelId,
        agentStageId,
        undefined,
        undefined,
        filter.status,
        filter.startDateRange?.[0].format(DATE_FORMAT),
        filter.startDateRange?.[1].format(DATE_FORMAT),
        filter.lastMessageDateRange?.[0].format(DATE_FORMAT),
        filter.lastMessageDateRange?.[1].format(DATE_FORMAT),
        deferredSearchText,
        sorting,
        loadMore ? conversationsPageIndex : 0,
        CONVERSATION_SEARCH_PAGE_SIZE
      );

      const result: DialogItem[] = (conversationsResponse.data.items ?? []).map((c) => ({
        isSelected: false,
        model: c,
      }));
      if (loadMore) {
        setConversations([...conversations, ...result]);
      } else {
        setConversations(result);
      }
      setConversationsPageIndex((conversationsResponse.data.pageIndex ?? conversationsPageIndex) + 1);
      setHasMore(conversationsResponse.data.hasMore ?? false);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке диалогов',
        error: e,
      });
    }
    setLoading(false);
  };

  const filterIsEmpty = !(filter.channelId || filter.status || filter.startDateRange || filter.lastMessageDateRange);

  const initFilter = () => {
    const channelId = query.get(CHANNEL_ID_PARAM);
    const status = query.get(CONVERSATION_STATUS_PARAM);
    const startFromDate = query.get(START_FROM_DATE_PARAM);
    const startToDate = query.get(START_TO_DATE_PARAM);
    const latestMessageFromDate = query.get(LATEST_MESSAGE_FROM_DATE_PARAM);
    const latestMessageToDate = query.get(LATEST_MESSAGE_TO_DATE_PARAM);
    const searchText = query.get(SEARCH_PARAM);

    let newFilter = {};

    if (channelId) {
      newFilter = {
        channelId,
      };
    }

    if (status) {
      newFilter = {
        ...newFilter,
        status: status as ConversationStatus,
      };
    }

    if (startFromDate && startToDate) {
      newFilter = {
        ...newFilter,
        startDateRange: [moment(startFromDate), moment(startToDate)],
      };
    }

    if (latestMessageFromDate && latestMessageToDate) {
      newFilter = {
        ...newFilter,
        lastMessageDateRange: [moment(latestMessageFromDate), moment(latestMessageToDate)],
      };
    }

    searchText && setSearchText(searchText);

    setFilter(newFilter);
    loadDialogs(false, newFilter).finally();
  };
  useEffect(initFilter, [agentStageId, deferredSearchText]);

  const onReloading = () => {
    if (reloading) {
      setConversations([]);
      initFilter();
    }
  };
  useEffect(onReloading, [reloading]);

  const initSelectedConversation = () => {
    const conversationId = query.get(CONVERSATION_ID_PARAM);
    conversationId && setSelectedConversationId(conversationId);
  };
  useEffect(initSelectedConversation, []);

  const onItemSelection = (item: DialogItem) => (item.isSelected = !item.isSelected);

  const dialogItem = [
    {
      render: (item: DialogItem) => (
        <DialogListItem item={item} selectable={selectable} onSelection={() => onItemSelection(item)} />
      ),
    },
  ];

  const onDialogSelect = (item: DialogItem) => {
    const newConversationId = item.model.id;
    setSelectedConversationId(newConversationId);
    if (newConversationId) {
      query.set(CONVERSATION_ID_PARAM, newConversationId);
    } else {
      query.delete(CONVERSATION_ID_PARAM);
    }
    replace({
      search: query.toString(),
    });
  };

  const onSearchChange = async (value: string) => {
    setSearchText(value);
    if (value) {
      query.set(SEARCH_PARAM, value);
    } else {
      query.delete(SEARCH_PARAM);
    }
    replace({
      search: query.toString(),
    });
  };

  const onFilterPopoverVisibleChange = (visible?: boolean) => setFilterPopoverVisible(visible || false);

  const onExportModalClose = () => closeDialogsExportModal();

  const onApplyFilter = async () => {
    await loadDialogs(false, filter);
    setFilterPopoverVisible(false);

    filter.channelId && query.set(CHANNEL_ID_PARAM, filter.channelId);
    filter.status && query.set(CONVERSATION_STATUS_PARAM, filter.status);
    if (filter.startDateRange) {
      query.set(START_FROM_DATE_PARAM, filter.startDateRange?.[0].format(DATE_FORMAT));
      query.set(START_TO_DATE_PARAM, filter.startDateRange?.[1].format(DATE_FORMAT));
    }
    if (filter.lastMessageDateRange) {
      query.set(LATEST_MESSAGE_FROM_DATE_PARAM, filter.lastMessageDateRange?.[0].format(DATE_FORMAT));
      query.set(LATEST_MESSAGE_TO_DATE_PARAM, filter.lastMessageDateRange?.[1].format(DATE_FORMAT));
    }
    replace({
      search: query.toString(),
    });
  };

  const onResetFilter = async () => {
    setFilter({});
    await loadDialogs(false, {});

    query.delete(CHANNEL_ID_PARAM);
    query.delete(CONVERSATION_STATUS_PARAM);
    query.delete(START_TO_DATE_PARAM);
    query.delete(START_FROM_DATE_PARAM);
    query.delete(LATEST_MESSAGE_TO_DATE_PARAM);
    query.delete(LATEST_MESSAGE_FROM_DATE_PARAM);
    replace({
      search: query.toString(),
    });
  };

  const onFilterChannelIdChange = (value: SelectValue) =>
    setFilter({
      ...filter,
      channelId: value as string,
    });

  const onFilterStatusChange = (value: SelectValue) =>
    setFilter({
      ...filter,
      status: value as ConversationStatus,
    });

  const onFilterStartDateChange = (values: [Moment | null, Moment | null] | null) =>
    setFilter({
      ...filter,
      startDateRange: values as [Moment, Moment],
    });

  const onFilterLastMessageDateChange = (values: [Moment | null, Moment | null] | null) =>
    setFilter({
      ...filter,
      lastMessageDateRange: values as [Moment, Moment],
    });

  const onSelectedDialogsExportButtonClick = () => runExportDialogsAsync(true).finally();

  const onDialogsExportByFilterButtonClick = () => runExportDialogsAsync(false).finally();

  const onDialogsDeselectAllButtonClick = () => {
    const newConversations: DialogItem[] = [...conversations].map((c) => ({ isSelected: false, model: c.model }));
    setConversations(newConversations);
    setSelectable(false);
  };

  const onCloseMobileMenu = () => {
    setMobileMenuVisible(false);
  };

  const onBubbleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!isTouchOnlyDevice() || e.defaultPrevented) {
      return;
    }

    setMobileMenuVisible(true);
    e.preventDefault();
  };

  const onBubbleClick = () => {
    if (isTouchOnlyDevice()) {
      return;
    }

    setMobileMenuVisible(true);
  };

  const filterMenuContent = (
    <IbTypography>
      <IbTypography.Paragraph strong>{t('Start date')}</IbTypography.Paragraph>
      <SbDatePicker.Range
        format={DATE_LOCAL_FORMAT}
        picker="date"
        value={filter.startDateRange}
        onChange={onFilterStartDateChange}
      />
      <IbTypography.Paragraph strong>{t('Latest message date')}</IbTypography.Paragraph>
      <SbDatePicker.Range
        format={DATE_LOCAL_FORMAT}
        picker="date"
        value={filter.lastMessageDateRange}
        onChange={onFilterLastMessageDateChange}
      />
      <IbTypography.Paragraph strong>{t('Channel')}</IbTypography.Paragraph>
      <IbSelect
        options={ChannelNames.map((c) => {
          return {
            label: c.label,
            value: c.value,
          };
        })}
        value={filter.channelId}
        onChange={onFilterChannelIdChange}
      />
      <IbTypography.Paragraph strong>{t('Status')}</IbTypography.Paragraph>
      <IbSelect
        options={ConversationStatusNames.map((s) => {
          return {
            label: s.label,
            value: s.value,
          };
        })}
        value={filter.status}
        onChange={onFilterStatusChange}
      />
      <div className={FILTER_MENU_BUTTONS_CLASS_NAME}>
        <IbButton onClick={onApplyFilter}>{t('Show')}</IbButton>
        <IbButton type="secondary" onClick={onResetFilter}>
          {t('Reset filter')}
        </IbButton>
      </div>
    </IbTypography>
  );

  const renderFilterButton = () => {
    return (
      <div className={SEARCH_FILTER_BUTTON_CLASS_NAME}>
        <IbIcon className={!filterIsEmpty ? 'badge' : ''} iconName="filter" />
      </div>
    );
  };

  const renderPopover = () => {
    return (
      <IbPopover
        className={FILTER_MENU_CLASS_NAME}
        content={filterMenuContent}
        placement="bottomLeft"
        trigger={['click']}
        visible={filterPopoverVisible}
        onVisibleChange={onFilterPopoverVisibleChange}
      >
        {renderFilterButton()}
      </IbPopover>
    );
  };

  const renderSearch = () => {
    return (
      <div className={SEARCH_CLASS_NAME}>
        <IbSearch
          placeholder={t('Search by dialogs')}
          searchValue={searchText}
          suffix={renderPopover()}
          onChange={onSearchChange}
        />
      </div>
    );
  };

  const renderDialogsExportModalContent = () => {
    switch (exportStatus) {
      case IbProgressStatusModalStatus.InProgress:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{t('This may take some time')}</IbTypography.Paragraph>
            <IbTypography.Paragraph>{t('Please wait')}</IbTypography.Paragraph>
          </IbTypography>
        );
      case IbProgressStatusModalStatus.Success:
        return (
          <IbTypography>
            <IbTypography.Paragraph>
              <a href={dialogsExport.fileUrl}>{t('Download link')}</a>
            </IbTypography.Paragraph>
          </IbTypography>
        );
      case IbProgressStatusModalStatus.Error:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{dialogsExport.errorMessage}</IbTypography.Paragraph>
          </IbTypography>
        );
    }
  };

  const renderDialogList = () => {
    const menuItems = [
      selectable
        ? {
            icon: <IbIcon iconName="undo" size={MENU_ICON_SIZE} />,
            text: t('Cancel selection'),
            onSelect: () => onDialogsDeselectAllButtonClick(),
          }
        : {
            icon: <IbIcon iconName="check-one" size={MENU_ICON_SIZE} />,
            text: t('Select dialogs'),
            onSelect: () => {
              const item = conversations.find((item) => item.model.id === selectedConversationId);
              if (item) {
                item.isSelected = true;
              }
              setSelectable(true);
            },
          },
      {
        icon: <IbIcon iconName="download" size={MENU_ICON_SIZE} />,
        text: t('Export all'),
        onSelect: () => onDialogsExportByFilterButtonClick(),
      },
    ];
    return (
      <>
        <IbContextMenu
          menuItems={menuItems}
          trigger="rightClick"
          visible={mobileMenuVisible}
          onClose={onCloseMobileMenu}
        >
          <div className={LIST_CLASS_NAME} onClick={onBubbleClick} onTouchEnd={onBubbleTouchEnd}>
            <SbScroll containerRefElement={scrollContainer} id={DIALOG_ITEMS_SCROLL_ID}>
              <InfiniteScroll
                dataLength={conversations.length}
                hasMore={hasMore}
                loader={<Skeleton active avatar />}
                next={() => loadDialogs(true, filter)}
                scrollableTarget={DIALOG_ITEMS_SCROLL_ID}
              >
                <Table
                  columns={dialogItem}
                  dataSource={conversations}
                  locale={{
                    emptyText: loading && <Skeleton active avatar />,
                  }}
                  pagination={false}
                  rowKey={(item) => item.model.id}
                  rowSelection={{ selectedRowKeys: [selectedConversationId || ''] }}
                  showHeader={false}
                  tableLayout="fixed"
                  onRow={(item) => {
                    return {
                      onClick: () => {
                        onDialogSelect(item);
                      },
                    };
                  }}
                />
              </InfiniteScroll>
            </SbScroll>
          </div>
        </IbContextMenu>
        {selectable && (
          <div className={EXPORT_CLASS_NAME}>
            <div className={EXPORT_COUNT_CLASS_NAME}>
              <IbIcon iconName="check-correct" size={24} />
              <span>{selectedDialogs.length}</span>
            </div>
            <div className={EXPORT_BUTTONS_CLASS_NAME}>
              <IbButton type="link" onClick={onDialogsDeselectAllButtonClick}>
                {t('Deselect all')}
              </IbButton>
              <IbButton type="link" onClick={onSelectedDialogsExportButtonClick}>
                {t('Export')}
              </IbButton>
            </div>
          </div>
        )}
        <IbProgressStatusModal
          header={exportTitle}
          status={exportStatus}
          visible={exportModalVisible}
          onCancel={onExportModalClose}
        >
          {renderDialogsExportModalContent()}
        </IbProgressStatusModal>
      </>
    );
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      {renderSearch()}
      {renderDialogList()}
    </div>
  );
};

export default DialogList;
