import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import { ActivityModel } from '../../../../../../api';
import IbIcon from '../../../../components/common/IbIcon';
import IbTypography from '../../../../components/common/IbTypography';

import {
  ARTICLE_CLASS_NAME,
  ERROR_DETAILS_CLASS_NAME,
  FRAME_CLASS_NAME,
  FRAME_TEXT_CLASS_NAME,
  MAIN_CLASS_NAME,
  TITLE_CLASS_NAME,
} from './const';

interface IErrorInfoProps {
  activity: ActivityModel;
}

const ErrorInfo: React.FC<IErrorInfoProps> = ({ activity }) => {
  const { t } = useTranslation();
  return (
    <div className={MAIN_CLASS_NAME}>
      <div className={TITLE_CLASS_NAME}>{t('About error')}</div>
      <div className={FRAME_CLASS_NAME}>
        <IbTypography>
          <IbTypography.Paragraph type="secondary">
            {activity.value.ClassName ?? activity.value.Source}
          </IbTypography.Paragraph>
          <IbTypography.Paragraph strong className={FRAME_TEXT_CLASS_NAME}>
            {activity.value.Message}
          </IbTypography.Paragraph>
        </IbTypography>
      </div>
      <div className={ARTICLE_CLASS_NAME}>
        <IbIcon iconName="attention" size={20} />
        <span>{t('Error details')}</span>
      </div>
      <div className={ERROR_DETAILS_CLASS_NAME}>{activity.value.StackTraceString ?? activity.value.StackTrace}</div>
    </div>
  );
};

export default ErrorInfo;
