import React from 'react';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { useTranslation } from 'react-i18next';

import './index.less';

import { ActivityDirection, ActivityModel, InboxDirection } from '../../../../../../api';
import { markdownSchema } from '../../const';
import Attachments from '../Attachments';
import SuggestedActions from '../SuggestedActions';
import { formatTimeShort } from '../../../../../utils/stringUtil';

import { MAIN_CLASS_NAME, USER_SELECTOR_NAME, OPERATOR_SELECTOR_NAME, BOT_SELECTOR_NAME } from './const';

interface IMessageBubbleProps {
  activity: ActivityModel;
  direction: ActivityDirection | undefined;
  isSelected: boolean;
  inboxDirection: InboxDirection;
  onSelect: (activity: ActivityModel) => void;
  getMenuItemSelected: (activity: ActivityModel) => ActivityModel | undefined;
}

const MessageBubble: React.FC<IMessageBubbleProps> = ({
  activity,
  direction,
  isSelected,
  inboxDirection,
  onSelect,
  getMenuItemSelected,
}) => {
  const { t, i18n } = useTranslation();
  // TODO: изменить механизм определения того, что собщение от оператора.
  // Сейчас подходящего свойства в activity нет, поэтому пока считается, что сообщения от оператора == внутренние сообщения
  const role =
    inboxDirection === InboxDirection.Internal
      ? OPERATOR_SELECTOR_NAME
      : direction == ActivityDirection.Inbound
      ? USER_SELECTOR_NAME
      : BOT_SELECTOR_NAME;
  const messageContainerClass = `${MAIN_CLASS_NAME}__${role}-message-container`;

  const messageContainerClasses = [messageContainerClass];
  inboxDirection === InboxDirection.Internal && messageContainerClasses.push(`${messageContainerClass}_internal`);

  const onMessageClick = () => onSelect(activity);

  const menuItemSelected = getMenuItemSelected(activity);

  return (
    <>
      <div className={messageContainerClasses.join(' ')}>
        <div
          className={`${messageContainerClass}__message ${
            isSelected ? `${messageContainerClass}__message_selected` : ''
          }`}
          onClick={onMessageClick}
        >
          <div className={`${messageContainerClass}__message__text`}>
            <Markdown
              rehypePlugins={[rehypeRaw, rehypeSanitize]}
              remarkPlugins={[remarkBreaks]}
              remarkRehypeOptions={markdownSchema}
            >
              {activity.text || `(${t('Empty message')})`}
            </Markdown>
          </div>
          <div className={`${messageContainerClass}__message__time`}>
            {formatTimeShort(activity.date, i18n.language)}
          </div>
        </div>
      </div>
      {!!activity.attachments?.length && <Attachments attachments={activity.attachments} role={role} />}
      {!!activity.suggestedActions?.length && (
        <SuggestedActions items={activity.suggestedActions} selectedItem={menuItemSelected?.text} />
      )}
    </>
  );
};

export default MessageBubble;
